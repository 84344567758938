
// const baseurl="https://shmy0ykpgi.execute-api.ap-south-1.amazonaws.com/prod"
const baseurl="https://1d0kb2o9z4.execute-api.ap-south-1.amazonaws.com/Prod/"
// const baseurl="http://192.168.5.80:8000"
const KeyId= 'AKIA6ODU53GIYKVZZEME'
const AccessKey= '4QEKsm4IzybrtW5Sf0zLkFZp5bCtgBW5n1fIPtB9'
const toi=["Fees","Services"]
const types=["Lumpsum","Percentage","On Sqft"]
const banknames=['Misc','Bandhan-20100018657972','Bharat-612100014610','Bharat-610100084505','DNS-29010100001263','HDFC-1451050122678','HDFC-501000174801181']
const ProjectStatus=["Active","Inactive","Working","Working Active","Completed"]
const Agency=['SRA','Mhada','General','PMC']
const companies=["Neo","BZ","PMC"]
const tds=['No TDS Cut','TDS cut but not Paid',"TDS Claimed"]
const gst=['GST not Charged',"GST Charged to be Paid","GST Paid","GST to be Claimed","GST Claimed"]
const wards=["A","B","C","D","E","F/N","F/S","G/N","G/S","H/E","H/W","K/E","K/W","L","M/E","M/W","N","P/N","P/S","R/N","R/S","R/C","S","T"]

export {baseurl,KeyId,AccessKey,toi,ProjectStatus,banknames,types,Agency,tds,gst,wards}

// https://jiycm07tpk.execute-api.ap-south-1.amazonaws.com/prod